import $ = require('jquery');

function createCookie(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + value + expires + '; path=/';
}

export function initCookieConsent(): void {
    const acceptCookiesContainer = $('#cookieConsentContainer');
    acceptCookiesContainer.addClass('show');
    $('#acceptCookies').click(function () {
        createCookie('CONSENT', '1', 365);
        acceptCookiesContainer.remove();
    });
}